import { Tooltip } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { blue } from "@material-ui/core/colors";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SettingsRemoteOutlinedIcon from "@material-ui/icons/SettingsRemoteOutlined";
import React, { useEffect, useState } from "react";
import * as steelseries from "steelseries";
import axiosInstance from "../../../shared/api";
import { checkNodeIsActive, utcToMtyConvertionDisplay } from "../../../shared/DateTimeConvertion";
import { refetchApi } from '../../../shared/utils';
import AlarmModal from "./AlarmModal";
import ProfileModal from "./ProfileModal";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import TimelineIcon from '@material-ui/icons/Timeline'
import { useHistory } from "react-router-dom";

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "300px",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: "500px",
        mixHeight: "500px",
        maxHeight: "500px",
        overflow: "scroll",
    },
    paperAlarm: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: "1000px",
        mixHeight: "500px",
        maxHeight: "500px",
        overflow: "scroll",
    },
    paperProfile: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "10px",
        width: "80%",
        height: "70%",
        overflowY: "scroll",
        [theme.breakpoints.between('xs', 'sm')]: {  // Small screens
            width: "80%",
        },
        [theme.breakpoints.between('sm', 'md')]: {  // Medium screens
            width: "60%",
        },
        [theme.breakpoints.between('md', 'lg')]: {  // Large screens
            width: "50%",
        },
        [theme.breakpoints.up('lg')]: {  // Extra-large screens and up
            width: "30%",
            height: "fit-content"
        },
    },
}));

function CardSingleNodeV3({ node, lcdId, ms }) {
    const classes = useStyles();
    const [openEditNode, setOpenEditNode] = useState(false);
    const [deviceId, setDeviceId] = useState("");
    const [openProfileNode, setOpenProfileNode] = useState(false);
    const [sensorSet, setSensorSet] = useState("");
    const [isDisplayToolbox, setIsDisplayToolbox] = useState(false);
    const [labels, setLabels] = useState({})
    const history = useHistory()

    const handleEditNodeModal = (shown, device_id) => {
        setDeviceId(device_id);
        setOpenEditNode(shown);
    };

    const handleProfileModal = (shown, device_id) => {
        setDeviceId(device_id);
        setSensorSet(node.sensor_set);
        setOpenProfileNode(shown);
    };

    useEffect(() => {
        if (ms == undefined) {
            let led = new steelseries.Led(document.getElementById(lcdId), {
                size: 25,
            });
            if ( utcToMtyConvertionDisplay(node['last_update']) == 'Invalid date') {
                led.setLedColor(steelseries.LedColor.RED_LED);
            } else if ( checkNodeIsActive( node["last_update"] ) ) {
                led.setLedColor(steelseries.LedColor.GREEN_LED);
            } else {
                led.setLedColor(steelseries.LedColor.RED_LED);
            }
            led.setLedOnOff(true);
        } else {
            let led = new steelseries.Led(document.getElementById(lcdId + ms), {
                size: 25,
            });
            if ( utcToMtyConvertionDisplay(node['last_update']) == 'Invalid date') {
                led.setLedColor(steelseries.LedColor.RED_LED);
            } else if ( checkNodeIsActive( node["last_update"] ) ) {
                led.setLedColor(steelseries.LedColor.GREEN_LED);
            } else {
                led.setLedColor(steelseries.LedColor.RED_LED);
            }
            led.setLedOnOff(true);
        }
    }, [node]);

    const [intervalHandle, setIntervalHandle] = useState(0);
    const setIntervalToRetrieveData = () => {
        if (node.isActive) {
            const handle = setInterval(() => {
                getDataNewApi()
                // getNodeSummary();
            }, 60000);
            setIntervalHandle(handle);
        }
    };

    const clearIntervalToRetrieveData = () => {
        clearInterval(intervalHandle);
    };

    const [nodeParameters, setNodeParameters] = useState([]);
    const [isLoadingNodeSummary, setIsLoadingNodeSummary] = useState(false);
    const [lastUpdateTime, setLastUpdateTime] = useState("");

    function getDataNewApi() {
        setIsLoadingNodeSummary(true)

        axiosInstance.get(`${process.env.REACT_APP_API_URL}/dashboard/summary/${node.device_id}`).then(res => {
            setIsLoadingNodeSummary(false)
            // Node Summary Label
            let temp = {}
            for (let i of res.data.label) {
                for (let x of res.data.data) {
                    if (i.sensor_set == x.sensor_set) {

                        // Switch Labelling
                        if (i.control_category == 'switch') {
                            let controlStatus = i.signal_type.split(';')
                            let statusObj = {} // { 1 : 'on' , 2 : 'off' }
                            for (let c in controlStatus) {
                                let status = controlStatus[c].split('-')[0]
                                let statusLabel = controlStatus[c].split('-')[1]
                                statusObj[status] = statusLabel.toUpperCase()
                            }
                            
                            temp[i.label] = x[i.parameter] ? statusObj[parseInt(x[i.parameter])] : '-'
                        } else {
                            // Normal Labelling
                            // If no unit then dont show unit.
                            if (i.unit == null || i.unit == 'undefined') {
                                temp[i.label] = x[i.parameter] ? parseFloat(x[i.parameter]).toFixed(2) : '-'
                            } else {
                                temp[i.label] = x[i.parameter] ?  parseFloat(x[i.parameter]).toFixed(2) + " " + i.unit : '- ' + i.unit
                            }
                        }
                    }
                }
            }
            
            // ph setpoint
            if (res.data.setpoint && res.data.setpoint.length > 0) {
                for (const setpoint of res.data.setpoint) {
                    temp[setpoint.label] = setpoint.value
                }
            }

            // Last pH and EC label
            if (Object.keys(res.data.cal_ph).length > 0) {
                temp[res.data.cal_ph.label] = res.data.cal_ph.datetime
            }
            if (Object.keys(res.data.cal_ec).length > 0) {
                temp[res.data.cal_ec.label] = res.data.cal_ec.datetime
            }
            
            // transplant date
            if (res.data.transplant.length > 0) {
                for (const transplant of res.data.transplant) {
                    temp[transplant.label] = transplant.date
                }
            }

            // Last Update label
            if (res.data.data.length > 0) {
                const convertedLastUpdateTime = utcToMtyConvertionDisplay(res.data.data[0]['datetime']);
                const humanFriendlyTime = moment(convertedLastUpdateTime, "YYYY-MM-DD, HH:mm").fromNow();
                setLastUpdateTime(humanFriendlyTime);
            } else {
                setLastUpdateTime('-')
            }
            setLabels(temp)
            
        }).catch( err => {
            setIsLoadingNodeSummary(false)
            console.log(err)
        })
    }

    const getNodeSummary = async () => {
        setIsLoadingNodeSummary(true);
        fetch(
            `${process.env.REACT_APP_API_URL}/getDashboardDataAll/${node.device_id}/`,
            {
                headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
            }
        )
            .then((res) => {
                return res.json();
            })
            .then(
                (result) => {
                    if (result.code && result.code === 'token_not_valid') {
                        refetchApi();
                    }

                    const nodeParameters = result["chart_prop_single"];
                    const targetData = result["data"].find(dataSet => dataSet.sensor_set === node.sensor_set);
                    const convertedLastUpdateTime = utcToMtyConvertionDisplay(targetData["datetime"]);
                    const humanFriendlyTime = moment(convertedLastUpdateTime, "YYYY-MM-DD, HH:mm").fromNow();

                    setLastUpdateTime(humanFriendlyTime);
                    const processedNodeParameters = nodeParameters.map(p => {
                        return {
                            parameter: p.parameter,
                            label: p.label,
                            unit: p.unit
                        };
                    });
                    processedNodeParameters.forEach(p => {
                        let value = targetData[`${p.parameter}`];
                        p["value"] = parseFloat(value).toFixed(2);
                    });
                    setNodeParameters(processedNodeParameters);


                    setIsLoadingNodeSummary(false);
                });
    };

    const handleClickDisplayTool = () => {
        setIsDisplayToolbox(prevState => {
            if (prevState === false) {
                getDataNewApi()
                // getNodeSummary();
                setIntervalToRetrieveData();
            } else {
                clearIntervalToRetrieveData();
            }
            return !prevState;
        });
    };

    return (
        <>
        <Modal className={classes.modal}
            open={openProfileNode}
            onClose={() => handleProfileModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <div className={classes.paperProfile}>
                <ProfileModal device_id={deviceId} sensor_set={sensorSet} setOpenProfileNode={setOpenProfileNode}/>
            </div>
        </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openEditNode}
                onClose={() => handleEditNodeModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openEditNode}>
                    <div className={classes.paperAlarm}>
                        <AlarmModal device_id={deviceId} serial={node.serial} />
                    </div>
                </Fade>
            </Modal>
            <div className="card card-container mx-auto bg-light mb-3">
                <div className="card-header" role="button" style={{paddingBottom: "2px"}}>
                    <div className="row">
                        <div className="col">
                            <a href="javascript:void(0)" onClick={()=>{ return window.location.href = "#/v2/node?device=" + node.device + "&device_id=" + node.device_id }}>{node.tag_name == null ? node.serial : node.tag_name}</a>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-8 col-md-7 col-lg-8">
                            <div style={{ fontSize: 10 }}>
                                {`${node.serial} [${node.sensor_set}]`}
                            </div>
                        </div>
                        <div className="col-8 col-md-7 col-lg-8 mt-1">
                            <div style={{ fontSize: 10 }}>
                                {node.date_installation}
                            </div>
                        </div>
                        <div className="col-4 col-md-5 col-lg-4 px-0">
                            <div className="d-flex justify-content-end">
                                <Tooltip
                                    title={"Last Update: " + utcToMtyConvertionDisplay(node.last_update)}
                                    TransitionComponent={Zoom}
                                    arrow
                                >
                                    {ms == undefined ? (
                                        <canvas id={lcdId}></canvas>
                                    ) : (
                                        <canvas id={lcdId + ms}></canvas>
                                    )}
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2" style={{justifyContent:"space-around"}}>
                        {node.remote && (
                        <div role="button" style={{padding:0, textAlign: "center"}}>
                            <div onClick={() => {
                                console.log("node.device", node.device)
                                if (node.device == 'led') {
                                    return window.location.href = "#/remote/" + node.device + "/" + node.serial + "/" + node.remote_id + "/" + node.telemetry
                                } else if (node.device == "ar00xx") {
                                    return window.location.href = "#/remote-block/" + node.device + "/" + node.serial + "/" + node.telemetry
                                } else if (node.device == "ar00xx_rb" || node.device == "ar00xx_3tb" || node.device == "ar00xx_1tb" || node.device == "ar00xx_plus") {
                                    return window.location.href = "#/remote-agrirrig-rad/block/" + node.device + "/" + node.device_id
                                } else if ( node.device == "hc00xx_pressure" ) {
                                    return window.location.href = "#/iko-pressure/"+ node.device +"/"+  node.serial
                                } else if (node.device.includes('_poll')) {
                                    return window.location.href = "#/remote/" + node.device + "/" + node.serial + "/" + node.remote_id + "/" + node.telemetry
                                } else if (node.device.includes('_mqtt')) {
                                    return window.location.href = "#/remote/" + node.device + "/" + node.serial + "/" + node.remote_id + "/" + node.telemetry
                                } else {
                                    return window.location.href = "#/remote/" + node.device + "/" + node.serial + "/" + node.remote_id + "/" + node.telemetry
                                }
                            }}>
                            <Tooltip title="Remote" TransitionComponent={Zoom} >
                                <SettingsRemoteOutlinedIcon
                                    style={{
                                        color: blue[500],
                                        fontSize: 25,
                                    }}
                                />
                            </Tooltip>
                            </div>
                        </div>
                        )}

                        <div role="button" style={{padding:0, textAlign: "center"}}>
                            <Tooltip
                                title="Chart History"
                                TransitionComponent={Zoom}
                                arrow
                                onClick={() => (window.location.href = "#/retrieve-chart?device_id=" + node.device_id + "&page=chart") } >
                                <TimelineIcon
                                    style={{ color: blue[500], fontSize: 25 }}
                                />
                            </Tooltip>
                        </div>
                        <div role="button" style={{padding:0, textAlign: "center"}}>
                            <Tooltip
                                title="Alarm History"
                                TransitionComponent={Zoom}
                                arrow
                                onClick={() => history.push(`/alarm/${node.device}/${node.device_id}`)}
                            >
                                <NotificationsNoneIcon
                                    style={{ color: blue[500], fontSize: 25 }}
                                />
                            </Tooltip>
                        </div>
                        <div role="button" style={{padding:0, textAlign: "center"}}>
                            <Tooltip
                                title="Profile"
                                TransitionComponent={Zoom}
                                arrow
                                onClick={() =>
                                    handleProfileModal(true, node.device_id)
                                }
                            >
                                <AccountCircleOutlinedIcon
                                    style={{ color: blue[500], fontSize: 25 }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="row mt-3" style={{justifyContent:"center"}}>
                        <button className="btn border-0 p-0" onClick={(e) => {
                            e.stopPropagation();
                            handleClickDisplayTool();
                        }}>
                            {isDisplayToolbox ?
                                <KeyboardArrowUpIcon style={{ color: blue[500], fontSize: 35, }}/>
                                :
                                <KeyboardArrowDownIcon style={{ color: blue[500], fontSize: 35,  }}/>
                            }
                        </button>
                    </div>
                </div>
                {isDisplayToolbox &&
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 px-1">
                                {isLoadingNodeSummary ? (<h6 className="text-center">Loading...</h6>) : <h6 className="text-center">Last Update:</h6>}
                                <h6 className="text-center">{lastUpdateTime}</h6>
                                <ul className="p-1 list-group list-group-flush rounded">
                                    {/* {
                                        nodeParameters.map(p => (
                                            <li key={p.label} className="list-group-item p-2 d-flex justify-content-between bg-light">
                                                <span>{p.label}:</span><span>{p.value} {p.unit}</span>
                                            </li>
                                        ))
                                    } */}
                                    {
                                        Object.keys(labels).length>0 > 0 && Object.keys(labels).map((l, index) =>{
                                            return (
                                                <li key={index} className="list-group-item p-2 d-flex justify-content-between bg-light">
                                                    <span>{l}:</span><span>{labels[l]}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default CardSingleNodeV3;

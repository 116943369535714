import React, { useEffect, useState } from 'react'
import { Table, Button } from "reactstrap"
import axiosInstance from "../../shared/api"
import { errorToast } from "../../shared/notification";
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner';
import { useLocation } from 'react-router-dom'

const ImageEvent = () => {
    const [events, setEvents] = useState([])
	const [loading, setLoading] = useState(false)
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const imageNameQuery = searchParams.get("imageName")

    useEffect(() => {
        if (!imageNameQuery) {
            loadImageEvents()
        }
    }, [])

    async function loadImageEvents() {
        setEvents([])
        setLoading(true)
        const response = await axiosInstance.get(`https://api.cloudatik.com/event/v1.0/image/load/`, {
            headers: { 
                "Authorization": localStorage.getItem('cloudatik-access'),
                "Content-Type": "application/json"
            },
        })

        if (response.status == 200 && response.data) {
            setLoading(false)
            // console.log(response.data.images)
            setEvents(response.data.images)
        } else {
            setLoading(false)
            errorToast("Server Error. Please contact administrator.")
        }
    }

    async function handleDelete(imageId) {
        console.log("Delete ", imageId)
        setLoading(true)
        axiosInstance.delete(`https://api.cloudatik.com/event/v1.0/image/delete/${imageId}`, {
            headers: { 
                "Authorization": localStorage.getItem('cloudatik-access'),
                "Content-Type": "application/json"
            },
        }).then(res => {
            console.log("res", res)
        }).catch(err => {
            errorToast("Server Error. Please contact administrator.")
        }).finally(() => {
            setLoading(false)
            loadImageEvents()
        })
    }
    
    return (
        <div style={{ paddingRight: 20, paddingLeft: 20}}>
			{loading && <LoadingSpinner />}
            {!imageNameQuery ? (
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Date - Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map((event, index) => {
                            return (
                                <tr key={index}>
                                    <td>{event.tag}</td>
                                    <td style={{display:"flex", gap: 10}}>
                                        <Button size='sm' color='primary' href={`/#/image-event?imageName=${encodeURIComponent(event.url)}`} target="_blank">View</Button>
                                        <Button size='sm' color='secondary' onClick={()=>{handleDelete(event.imageid)}}>Delete</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            ):(
                <div style={{display:"flex", justifyContent:"center"}}>
                    <img src={imageNameQuery} alt="Event" style={{ maxWidth: '100%', height: '80vh' }} />
                </div>
            )}
        </div>
    )
}

export default ImageEvent
import React, { useEffect, useState, PureComponent, useLayoutEffect } from 'react'
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner'
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { TextField, Grid } from '@material-ui/core';
import queryString from "query-string";
import axiosInstance from '../../shared/api';

const Summary = () => {
    const [loading, setLoading] = useState(false)
	const { search } = useLocation()
	const values = queryString.parse(search)
    const [headers, setHeaders] = useState([])
    const [rows, setRows] = useState({})
    const [serialName, setSerialName] = useState("-")

    useEffect(() => {
        setHeaders([])
        setRows({})
        axiosInstance.get(`https://api.cloudatik.com/chart/v1.0/summary/${values.device_id}/`).then(res => {
            console.log("response", res.data)
            setSerialName(res.data.data.serial)
            for (let i of res.data.data.monthly) {
                
                for (let x of i.data) {
                    setHeaders(prev => {
                        if (!prev.includes(x.month)) {
                            return [...prev, x.month]
                        }
                        return prev
                    })
                }

                setRows(prev => ({
                    ...prev,
                    [i.description]: i.data    
                }))

            }
            // console.log("headers", headers)
            // console.log("rows", rows)
        }).catch(err => {
            console.log(234, err)
        })

    }, [])

    
    
    
    return (
		<div>
        {loading && <LoadingSpinner />}
            <Grid container style={{ textAlign: 'center', maxWidth: '750px', margin: 'auto', justifyContent:"space-evenly", marginBottom: "10px" }}>
                <Grid item xs={4}>
                    <Link to={`/retrieve-chart?device_id=${values.device_id}&page=chart`}>
                        <Button variant="contained" color="primary">Plot Chart</Button>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to={`/retrieve-chart?device_id=${values.device_id}&page=download`}>
                        <Button variant="contained" color="secondary">Download Raw Data</Button>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to={`/retrieve-summary?device_id=${values.device_id}&page=summary`}>
                        <Button variant="contained" color="info">Summary</Button>
                    </Link>
                </Grid>
            </Grid>

            <center style={{marginTop: "3rem", paddingLeft: "2rem", paddingRight: "2rem"}}>
                <h3 style={{ marginBottom: "2rem" }}>{serialName}</h3>
                <div style={{ overflowX: "auto", fontSize:"12px" }}>
                    <table className="table" style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ position: 'sticky', left: 0, zIndex: 2, backgroundColor:"#FFF", width: '5rem', paddingRight: "6rem"}} > Parameter </th>
                                {headers.map((header, index) => (
                                    <th key={index} style={{ width: '5rem' }}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(rows).map(([key, values], index) => (
                                <tr key={index}>
                                    <td style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1, wordWrap: 'break-word', whiteSpace: 'normal', }}><b>{key}</b></td>
                                    {values.map((value, valueIndex) => {
                                        if (value.month === headers[valueIndex]) {
                                            return (
                                                <td key={valueIndex} style={{ width: '200px' }} >
                                                    {value.value}
                                                </td>
                                            );
                                        }
                                        return null;
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </center>
            
		</div>
    )
}

export default Summary